<template>
  <div>
    <register-clients ref="RegisterClients" />
    <show-clients ref="ShowClients" />
  </div>
</template>
<script>
  export default {
    components: {
      RegisterClients: () => import('./components/RegisterClients'),
      ShowClients: () => import('./components/ShowClients'),
    },
  }
</script>
